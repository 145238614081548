import React, { useContext } from "react";

// Components
import Logout from "../logout/Logout";
import Notification from "../notification/Notification";

// Libraries
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "@hybris-software/use-auth";

// Context
import { RoutesContext } from "../../../contexts/RoutesContext";

// Hooks
import useText from "../../../hooks/useText";

// Data
import { accountType } from "../../../data/constants";
import config from "../../../data/config";

// Styles
import Style from "./MobileMenu.module.css";

const MobileMenu = ({
  openMobile,
  setOpenMobile,
  setOpenNotify,
  openNotify,
  logoutRef,
  telegramAPI,
}) => {
  const texts = useText("menu");

  const navigate = useNavigate();
  const location = useLocation();
  const { paths } = useContext(RoutesContext);
  const { userInfo } = useUser();

  const menuRoutes = [
    {
      name: texts?.dashboard,
      path: paths?.main?.dashboard,
      showInMobile: true,
    },
    {
      name: texts?.profile,
      path: paths?.main?.profile,
      showInMobile: true,
    },
    {
      name: texts?.email,
      path: paths?.main?.email,
      showInMobile: true,
    },
    {
      name: texts?.password,
      path: paths?.main?.password,
      showInMobile: true,
    },
    {
      name:
        userInfo?.accountType === accountType.PERSONAL
          ? texts?.kyc
          : texts?.kyb,
      path: paths?.main?.kyc,
      showInMobile: true,
    },
    {
      name: texts?.security,
      path: paths?.main?.security,
      showInMobile: true,
    },
    {
      name: texts?.notifications,
      path: paths?.main?.notification,
      showInMobile: true,
    },
    {
      name: texts?.devices,
      path: paths?.main?.devices,
      showInMobile: true,
    },
    {
      name: texts?.support,
      showInMobile: true,
    },
    {
      name: texts?.telegram,
      path: paths?.main?.telegram,
      showInMobile: userInfo?.telegramId === null,
    },
  ];

  return (
    <div
      className={openMobile ? Style.opened : Style.menu}
      style={
        sessionStorage.getItem("inspectorTool") === "true" ? { top: 120 } : {}
      }
    >
      <div className={Style.main}>
        <div className={Style.notifications}>
          <Notification
            open={openNotify}
            setOpen={setOpenNotify}
            type="mobile"
          />
          <Logout logoutRef={logoutRef} type="mobile" />
        </div>
        <div className={Style.dropdown}>
          <ul className={Style.responsiveList}>
            {menuRoutes.map((route, index) => {
              if (!route.showInMobile) return null;
              else
                return (
                  <li
                    key={index}
                    className={
                      route.path === location.pathname
                        ? Style.active
                        : undefined
                    }
                    onClick={() => {
                      if (route.path) {
                        navigate(route.path);
                      } else {
                        window.open(config.SUPPORT_LINK, "_blank");
                      }
                    }}
                  >
                    {route.name}
                  </li>
                );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
