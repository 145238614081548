const endpoints = {
  PUBLIC_PLATFORM_CONFIG: "platform-config/",
  auth: {
    REGISTER: "auth/register/",
    LOGIN: "auth/login/",
    LOGINCONFIRM: "auth/login/confirm/",
    RESENDOTP: "auth/login/resend-otp/",
    RESETPASSWORD: "auth/password/reset/",
    RESETPASSWORDCONFIRM: "auth/password/reset/confirm/",
    SUBMITREFERRAL: "auth/referral/submit/",
    SKIPREFERRAL: "auth/referral/skip/",
    ACCOUNTTYPE: "auth/account-type/",
    TERMSANDCONDITIONS: "profile/accept-terms-and-conditions/",
    PLATFORMCONFIG: "auth/settings/",
    USERINFO: "auth/user/",
    GET_TOKEN_SPY: "auth/delegate/login/",
    COUNTRIES: "auth/countries/",
  },
  logout: {
    LOGOUT: "auth/logout/",
    LOGOUTALL: "auth/logoutall/",
  },
  profile: {
    GETPLATFORMS: "platforms/",
    PLATFORM_LOGIN: "platforms/:id/login/",
    ENROLLPLATFORM: "platforms/enroll/",
    GETSTATS: "profile/stats/",
    UPDATEPROFILE: "profile/picture/",
    RECENTDEVICES: "profile/logins/",
    CHANGEPASSWORD: "auth/password/change/",
    CONFIRMPASSWORDCHANGE: "auth/password/change/confirm/",
    CHANGEEMAIL: "profile/email/change/",
    CONFIRMEMAILCHANGE: "profile/email/change/confirm/",
    NOTIFICATIONSETTINGS: "notifications/@settings/",
    PREFERREDLANGUAGE: "profile/language/",
    UPDATE_COUNTRY: "profile/country/update/",
    PRIVATE_KEY: "/profile/private-key/",
    TELEGRAM: "/profile/telegram-account-verify/",
  },
  user: {
    RESIDENTIALINFORMATION: "profile/residential-details/",
  },
  otp: {
    RESENDOTP: "otp/resend/",
    CHANGEOTPMETHOD: "otp/settings/method/",
    CHANGEOTPMETHODCONFIRM: "otp/settings/method/confirm/",
    OTP_SETTINGS: "otp/settings/totp/",
  },
  securityCode: {
    UPDATESECURITYCODE: "profile/security-code/",
  },
  kyc: {
    CHECKANDSTART: "kyc/start/",
    CHECK_STATUS: "kyc/check/",
  },
  notifications: {
    GETNOTIFICATIONS: "notifications/all",
    READ_NOTIFICATION: (id) => `notifications/${id}/read/`,
    READ_BROADCAST_NOTIFICATION: (id) => `notifications/broadcast/${id}/read/`,
    GET_TAGLIST: "notifications/tags-list/",
  },
  oauth: {
    OAUTH: "oauth2/authorize/",
  },
  doubleTwoFactor: {
    SECONDARY_TOTP: "otp/settings/secondary-totp/",
  },
  PLATFORM_CONFIG_PUBLIC: "platform-config/",
};

export default endpoints;
