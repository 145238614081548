import React from "react";

// Components
import ChangeAvatar from "../changeAvatar/ChangeAvatar";
import BusinessCard from "../businessCard/BusinessCard";

// Libraries
import { useUser } from "@hybris-software/use-auth";
import { Button } from "@hybris-software/ui-kit";

// Contexts
import { useOutletContext } from "react-router-dom";

// Images
import AvatarPlaceholder from "../../../assets/images/placeholder.jpg";

// Hooks
import useText from "../../../hooks/useText";

// Icons
import { GoPencil } from "react-icons/go";

// Styles
import Style from "./ProfileBadge.module.css";

const ProfileBadge = ({ modalRef }) => {
  // Hooks
  const texts = useText("profileBadge");
  const { userInfo } = useUser();

  // Contexts
  const { businessCardRef } = useOutletContext();

  return (
    <div className={Style.card}>
      <div className={Style.grid}>
        <div className={Style.userInfo}>
          <div
            className={Style.avatar}
            style={{
              backgroundImage: userInfo.profilePicture
                ? `url(${userInfo.profilePicture})`
                : `url(${AvatarPlaceholder})`,
            }}
          >
            <div
              className={Style.changeAvatar}
              onClick={() => {
                modalRef.current.updateBody(
                  <ChangeAvatar modalRef={modalRef} />,
                  {
                    onModalOpen: () => {
                      document.body.style.overflow = "hidden";
                    },
                    onBodyUpdate: () => {
                      document.body.style.overflow = "hidden";
                    },
                    onModalDestroy: () => {
                      document.body.style.overflow = "overlay";
                    },
                  }
                );
              }}
            >
              <GoPencil />
            </div>
          </div>

          <div className={Style.data}>
            <h5 className={Style.name}>@{userInfo?.username || "--"}</h5>
            <div className={Style.email}>{userInfo?.email || "--"}</div>
            <div className={Style.email}>
              {texts?.id}
              {userInfo?.id || "--"}
            </div>
          </div>
        </div>
        <div className={Style.wrapper}>
          <div className={Style.date}>
            {texts?.date}{" "}
            {userInfo?.dateJoined
              ? new Date(userInfo?.dateJoined).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "--"}
          </div>

          <Button
            className={Style.button}
            onClick={() => {
              businessCardRef.current.updateBody(<BusinessCard />);
            }}
          >
            {texts?.businessCard}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileBadge;
