import config from "./config";

// Global Variables
const steps = {
  REFERRAL_CODE: 0,
  ACCOUNT_TYPE: 1,
  TERMS: 2,
  CHANGE_OTP: 3,
  ENROLL: 4,
};
const otpMethods = {
  EMAIL: "EMAIL",
  TOTP: "TOTP",
};
const accountType = {
  PERSONAL: "PERSONAL",
  BUSINESS: "BUSINESS",
};

const kycProviders = {
  MANUAL_KYC: "MANUAL_KYC",
  PROTOAML: "PROTOAML",
};

const ssoErrorCode = {
  REFERRAL_NOT_SUBMITTED: {
    message: "You did not complete the referral step",
    actionAllowed: true,
  },
  COUNTRY_NOT_ALLOWED: {
    message: "Your country is currently not accepted for the platform.",
    actionAllowed: false,
  },
  USER_NOT_ALLOWED: {
    message: "You are not allowed to access this platform.",
    actionAllowed: false,
  },
  LOGIN_NOT_ALLOWED: {
    message: "You are currently not allowed to access this platform.",
    actionAllowed: false,
  },
  INVALID_TOKEN: {
    message: "The token provided is not valid or has expired.",
    actionAllowed: false,
  },
  INVALID_REQUEST: {
    message:
      "The information you provided might be missing or not quite right.",
    actionAllowed: false,
  },
  TERMS_NOT_ACCEPTED: {
    message: "You did not accept the terms and condition",
    actionAllowed: true,
  },
  ACCOUNT_TYPE_NOT_SET: {
    message: "You did not chose account type",
    actionAllowed: true,
  },
  FORBIDDEN_STAFF_ACCOUNT: {
    message: "You are not allowed to do this action",
    actionAllowed: false,
  },
  NOT_STAFF_ACCOUNT: {
    message: "You are not a staff member",
    actionAllowed: false,
  },
  TOTP_NOT_ENABLED: {
    message: "You did not change your OTP to Google Authenticator",
    actionAllowed: true,
  },
  USER_SHOULD_PURCHASE_KMALL_MEMBERSHIP: {
    message: `To login, you must first purchase a ${config.LFI_PLATFORM_NAME} membership`,
    actionAllowed: false,
  },
};

const notificationsTypes = {
  LOGIN: "LOGIN",
  REFERRAL: "REFERRAL",
  NEW_DEVICE: "NEW_DEVICE",
  SECURITY_CODE: "SECURITY_CODE",
  PASSWORD_CHANGE: "PASSWORD_CHANGE",
  EMAIL_CHANGE: "EMAIL_CHANGE",
  PLATFORM_LOGIN: "PLATFORM_LOGIN",
  PLATFORM_ENROLLMENT: "PLATFORM_ENROLLMENT",
  KYC_APPROVED: "KYC_APPROVED",
  KYC_REJECTED: "KYC_REJECTED",
  TFA_SETTINGS: "TFA_SETTINGS",
  RESIDENTIAL_INFO_CHANGE: "RESIDENTIAL_INFO_CHANGE",
  BROADCAST: "BROADCAST",
};

export {
  steps,
  otpMethods,
  ssoErrorCode,
  accountType,
  kycProviders,
  notificationsTypes,
};
