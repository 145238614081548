import React, { useEffect, useState } from "react";

// Components
import StatusResponse from "../../../../../components/advanced/statusResponse/StatusResponse";
import SelectComponent from "../../../../../components/advanced/SelectComponent/SelectComponent";
import UnauthorizedModal from "../../../../../components/advanced/unauthorizedModal/UnauthorizedModal";
// Libraries
import useForm from "@hybris-software/use-ful-form";
import { InputField, Button } from "@hybris-software/ui-kit";
// import CountryList from 'country-list-with-dial-code-and-flag'
import { useUser } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useOutletContext } from "react-router-dom";

// Hooks
import useText from "../../../../../hooks/useText";

// Icons
import { FiEdit3 } from "react-icons/fi";

// Images
import Success from "../../../../../assets/images/success.webp";

// Utils
import updateFormError from "../../../../../utils/updateFormError";
import countryList from "../../../../../utils/countryList";

// Data
import endpoints from "../../../../../data/endpoints";

// Styles
import Style from "./PersonalInfo.module.css";
import { accountType } from "../../../../../data/constants";

const PersonalInfo = ({ response, modalRef, countries }) => {
  // Hooks
  const texts = useText("profileView")?.personalInformation;
  const textsAddress = useText("profileView")?.residentialAddress;
  const telegramTexts = useText("profileView")?.telegramInfo;
  const successTexts = useText("success");
  const { userInfo, refreshUserInfo } = useUser();
  const { unauthorizedModalRef } = useOutletContext();

  const [editable, setEditable] = useState(false);

  const filteredCountries = countries?.filter((country) => {
    return country?.disabled === false;
  });

  const form = useForm({
    inputs: {
      country: {
        value:
          response?.country &&
          countries?.find((item) => item.code === response?.country)?.name,
        required: true,
      },
      city: {
        value: response?.city || "",
        required: true,
      },
      address: {
        value: response?.address || "",
        required: true,
      },
      zipCode: {
        value: response?.zipCode || "",
        formatter: (value) => {
          return value.replace(/[^a-zA-Z0-9]/g, "")?.toUpperCase();
        },
        required: true,
      },
      phone: {
        value: response?.phone || "",
        formatter: (value) => {
          // Remove all non-numeric characters except '+'
          value = value.replace(/[^0-9+]/g, "");

          // Allow '+' sign only at the beginning
          if (value.startsWith("+")) {
            return "+" + value.substring(1).replace(/[^0-9]/g, "");
          } else {
            return value.replace(/[^0-9]/g, "");
          }
        },
        required: true,
      },
    },
  });
  const editResidentialInfoApi = useQuery({
    url: endpoints.user.RESIDENTIALINFORMATION,
    method: "PUT",
    onSuccess: (response) => {
      refreshUserInfo();
      form
        .getInputProps("country")
        .setValue(
          filteredCountries?.find(
            (item) => item.code === form.values["country"]
          )?.name
        );
      setEditable(false);
      modalRef?.current?.updateBody(
        <StatusResponse
          icon={Success}
          title={successTexts?.informationTitle}
          description={successTexts?.informationSubtitle}
        >
          <Button
            onClick={() => {
              modalRef?.current?.destroy();
            }}
            style={{
              borderRadius: "30px",
              width: "100%",
              maxWidth: "200px",
              marginBottom: "10px",
            }}
          >
            {successTexts?.buttonClose}
          </Button>
        </StatusResponse>
      );
    },
    onError: (error) => {
      if (error.response?.status === 422) {
        updateFormError(error.response?.data, form);
      } else if (error.response?.status === 401) {
        modalRef?.current?.updateBody(<UnauthorizedModal />);
      }
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  const countryError = form.getInputProps("country").errorDetails;

  useEffect(() => {
    if (editable) form.getInputProps("country").setValue(userInfo?.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);
  return (
    <div className={Style.personal}>
      {userInfo?.accountType === accountType.PERSONAL && (
        <div className={Style.data}>
          <h5>{texts?.title}</h5>
          <div className={Style.grid}>
            <div className={Style.item}>
              <div className={Style.label}>{texts?.labelName}</div>
              <div className={Style.values}>{userInfo?.firstName || "_"}</div>
            </div>
            <div className={Style.item}>
              <div className={Style.label}>{texts?.labelLastName}</div>
              <div className={Style.values}>{userInfo?.lastName || "_"}</div>
            </div>
            <div className={Style.item}>
              <div className={Style.label}>{texts?.labelCountry}</div>
              <div className={Style.values}>
                {userInfo?.documentCountry ? userInfo?.documentCountry : "_"}
              </div>
            </div>
            <div className={Style.item}>
              <div className={Style.label}>{texts?.labelAccount}</div>
              <div className={Style.values}>{userInfo?.accountType || "_"}</div>
            </div>
          </div>
        </div>
      )}
      <div className={Style.residential}>
        <div className={Style.editable}>
          <h5>{textsAddress?.title}</h5>
          {!editable && (
            <div className={Style.edit} onClick={() => setEditable(!editable)}>
              {textsAddress?.editButton} <FiEdit3 />
            </div>
          )}
        </div>
        <div className={editable ? Style.gridAddress : Style.grid}>
          {editable ? (
            <div className={Style.country}>
              <div className={Style.label}>{texts?.labelCountry}</div>

              <SelectComponent
                className={countryError ? Style.selectError : ""}
                placeholder={texts?.placeholderCountry}
                maxHeightOpened={300}
                items={filteredCountries}
                searchKey="name"
                renderOption={(item, fullItem) => (
                  <div className={Style.option}>
                    <span className={Style.flag}>
                      <img
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          countryList?.find(
                            (item) => item.code === fullItem.code
                          )?.flag
                        )}`}
                      />
                    </span>
                    {fullItem.name}
                  </div>
                )}
                value={filteredCountries?.find(
                  (item) => item.code === form.values["country"]
                )}
                setValue={(value) => {
                  form.getInputProps("country").setValue(value?.code);
                }}
              />

              {countryError && (
                <div className={Style.errorMessageClassName}>
                  {countryError}
                </div>
              )}
            </div>
          ) : (
            <InputField
              className={editable ? Style.input : Style.inputEditable}
              label={textsAddress?.labelCountry}
              placeholder={textsAddress?.placeholderCountry}
              readOnly={!editable}
              showError={editable}
              {...form.getInputProps("country")}
            />
          )}
          <InputField
            className={editable ? Style.input : Style.inputEditable}
            label={textsAddress?.labelCity}
            placeholder={textsAddress?.placeholderCity}
            readOnly={!editable}
            showError={editable}
            {...form.getInputProps("city")}
          />
          <InputField
            className={editable ? Style.input : Style.inputEditable}
            label={textsAddress?.labelAddress}
            placeholder={textsAddress?.placeholderAddress}
            readOnly={!editable}
            showError={editable}
            {...form.getInputProps("address")}
          />
          <InputField
            className={editable ? Style.input : Style.inputEditable}
            label={textsAddress?.labelZipCode}
            placeholder={textsAddress?.placeholderZipCode}
            readOnly={!editable}
            showError={editable}
            {...form.getInputProps("zipCode")}
          />
          <InputField
            className={editable ? Style.input : Style.inputEditable}
            label={textsAddress?.labelPhone}
            placeholder="+123 4567890"
            readOnly={!editable}
            showError={editable}
            {...form.getInputProps("phone")}
          />
        </div>
        {editable && (
          <div className={Style.buttons}>
            <Button
              className={Style.save}
              isLoading={editResidentialInfoApi.isLoading}
              onClick={() => {
                if (form.validate()) {
                  editResidentialInfoApi.executeQuery(form.getApiBody());
                }
              }}
            >
              {textsAddress?.saveButton}
            </Button>
          </div>
        )}
      </div>

      {userInfo?.telegramId && (
        <div className={Style.telegramInfo}>
          <h5>{telegramTexts?.title}</h5>

          <div className={Style.grid}>
            <div className={Style.item}>
              <div className={Style.label}>{telegramTexts?.labelId}</div>
              <div className={Style.values}>{userInfo?.telegramId || "_"}</div>
            </div>
            <div className={Style.item}>
              <div className={Style.label}>{telegramTexts?.labelUsername}</div>
              <div className={Style.values}>
                {userInfo?.telegramUserName || "_"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalInfo;
