import LogoWhite from "../assets/logos/logo-white.svg";
import LogoBlack from "../assets/logos/logo-black.svg";

let config = {
  LOGO_WHITE: LogoWhite,
  LOGO_BLACK: LogoBlack,
  PLATFORM_NAME: "2access",
  REDIRECT_URL_LABEL: "redirect",
  LOGIN_URL_PARAMS: "scope",
  MAX_FILE_SIZE: 1 * 1024 * 1024,
  REQUEST_REFERRAL_CODE_FORM_URL: "https://forms.gle/KnTi3N8d4kfRwTZm7",
  SUPPORT_LINK: "https://support.2access.io/",
  TERMS_LINK: "https://docs.2access.io/",
  LFI_PLATFORM_NAME: "KMALL",
};

if (process.env.NODE_ENV === "development") {
  config = {
    ...config,
    API_BASE_URL: "http://10.101.12.223:8000/api/v1/",
    DEBUG_MODE: true,
    WEBSOCKET_NOTIFICATION_URL: "ws://10.101.12.223:8000/ws/v1/notifications/",
    CAPTCHA_SITE_KEY: "10000000-ffff-ffff-ffff-000000000001",
    BASE_URL: "http://localhost:3001/",
  };
} else {
  const requiredEnvVars = [
    "REACT_APP_API_BASE_URL",
    "REACT_APP_DEBUG_MODE",
    "REACT_APP_WEBSOCKET_NOTIFICATION_URL",
    "REACT_APP_CAPTCHA_SITE_KEY",
    "REACT_APP_BASE_URL",
  ];

  requiredEnvVars.forEach((envVar) => {
    if (!process.env[envVar]) {
      throw new Error(`Missing required environment variable ${envVar}`);
    }
  });

  config = {
    ...config,
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    DEBUG_MODE: ["true", "1"].includes(
      process.env.REACT_APP_DEBUG_MODE.toLowerCase()
    ),
    WEBSOCKET_NOTIFICATION_URL:
      process.env.REACT_APP_WEBSOCKET_NOTIFICATION_URL,
    CAPTCHA_SITE_KEY: process.env.REACT_APP_CAPTCHA_SITE_KEY,
    BASE_URL: process.env.REACT_APP_BASE_URL,
  };
}

export default config;
