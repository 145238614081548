import React, { useEffect, useState, useContext } from "react";

// Components
import LoaderElement from "../../ui/loaderElement/LoaderElement";
import BroadcastDetailsModal from "../BroadcastDetailsModal/BroadcastDetailsModal";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Icons
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineMoreTime } from "react-icons/md";
import { TbReload } from "react-icons/tb";

// Hooks
import useTypeNotification from "../../../hooks/useTypeNotification";
import useText from "../../../hooks/useText";

// Data
import endpoints from "../../../data/endpoints";
import { notificationsTypes } from "../../../data/constants";

// Contexts
import NotificationContext from "../../../contexts/NotificationContext";

// Styles
import Style from "./NotificationsList.module.css";

const NotificationsList = ({
  openMobile,
  openNotification,
  setOpenNotification,
  allNotifications,
  setUrl,
  links,
  setAllNotificationsList,
  broadcastModalRef,
  mainNotificationAPI,
}) => {
  // Hooks
  const texts = useText("notificationsList");

  // States
  const [menuOpen, setMenuOpen] = useState("new");

  // Contexts
  const [notificationsList, setNotificationsList] =
    useContext(NotificationContext);
  console.log(notificationsList, "notificationsList");

  // Queries
  const getNotificationsApi = useQuery({
    url: endpoints.notifications.GETNOTIFICATIONS,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setUrl(endpoints.notifications.GETNOTIFICATIONS);
      setAllNotificationsList(response.data.results);
    },
  });
  const getTagListAPI = useQuery({
    url: endpoints.notifications.GET_TAGLIST,
    method: "GET",
    executeImmediately: true,
  });

  useEffect(() => {
    if (openNotification || openMobile) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "overlay";
  }, [openNotification, openMobile]);

  return (
    <div className={openNotification ? Style.listBox : Style.listBoxClosed}>
      <div className={Style.header}>
        <div>
          <h5 className={Style.title}>{texts?.title}</h5>
        </div>
        <div
          className={Style.close}
          onClick={() => {
            setMenuOpen("new");
            setOpenNotification(false);
          }}
        >
          <IoCloseSharp />
        </div>
      </div>

      <div className={Style.menu}>
        <div
          onClick={() => {
            setMenuOpen("new");
          }}
          className={menuOpen === "new" ? Style.selected : undefined}
        >
          {texts?.newNotifications}
        </div>
        <div
          onClick={() => {
            setUrl(endpoints.notifications.GETNOTIFICATIONS);
            setMenuOpen("all");
          }}
          className={menuOpen === "all" ? Style.selected : undefined}
        >
          {texts?.allNotifications}
        </div>
        <div
          onClick={() => {
            setMenuOpen("activities");
            setUrl(endpoints.notifications.GETNOTIFICATIONS + "?type=activity");
          }}
          className={menuOpen === "activities" ? Style.selected : undefined}
        >
          {texts?.activities}
        </div>
        {getTagListAPI?.response?.data?.length > 0 && (
          <>
            {getTagListAPI?.response?.data?.map((tag) => (
              <div
                onClick={() => {
                  setMenuOpen(tag.name);
                  setUrl(
                    `${endpoints.notifications.GETNOTIFICATIONS}?tab=${tag.name}`
                  );
                }}
                className={menuOpen === tag.name ? Style.selected : undefined}
                key={tag.id}
              >
                {tag.name}
              </div>
            ))}
          </>
        )}
      </div>

      <div className={Style.list}>
        {getNotificationsApi.isLoading ||
        getTagListAPI.isLoading ||
        mainNotificationAPI.isLoading ? (
          <div className={Style.loader}>
            <LoaderElement />
          </div>
        ) : (
          <>
            {(() => {
              switch (menuOpen) {
                case "new":
                  return notificationsList.length > 0 ? (
                    notificationsList.map((item, index) => (
                      <NotificationItem
                        key={index}
                        {...item}
                        deleting={true}
                        setNotificationsList={setNotificationsList}
                        broadcastModalRef={broadcastModalRef}
                      />
                    ))
                  ) : (
                    <div className={Style.empty}>{texts?.empty}</div>
                  );
                case "activities":
                  return allNotifications.length > 0 ? (
                    allNotifications.map((item, index) => (
                      <NotificationItem key={index} {...item} />
                    ))
                  ) : (
                    <div className={Style.empty}>{texts?.empty}</div>
                  );
                case "all":
                  return allNotifications.length > 0 ? (
                    allNotifications.map((item, index) => (
                      <NotificationItem
                        key={index}
                        {...item}
                        broadcastModalRef={broadcastModalRef}
                      />
                    ))
                  ) : (
                    <div className={Style.empty}>{texts?.empty}</div>
                  );
                default:
                  if (
                    getTagListAPI?.response?.data?.length > 0 &&
                    allNotifications.length > 0
                  ) {
                    return allNotifications.map((item, index) => (
                      <NotificationItem
                        key={index}
                        {...item}
                        broadcastModalRef={broadcastModalRef}
                      />
                    ));
                  }
                  return null;
              }
            })()}
          </>
        )}
      </div>

      {menuOpen !== "new" && (
        <div className={Style.buttons}>
          <Button
            className={Style.loadMore}
            isLoading={mainNotificationAPI.isLoading}
            onClick={() => {
              if (links?.next)
                setUrl(links?.next.replace("http://", "https://"));
            }}
          >
            {links?.next && (
              <>
                <MdOutlineMoreTime />
                {texts?.loadMore}
              </>
            )}
          </Button>{" "}
          <Button
            className={Style.reload}
            isLoading={mainNotificationAPI?.isLoading}
            onClick={() => {
              mainNotificationAPI.executeQuery();
            }}
          >
            <TbReload />
            {texts?.reload}
          </Button>
        </div>
      )}
    </div>
  );
};

const NotificationItem = ({
  type,
  createdAt,
  created_at,
  short_description,
  deleting,
  id,
  setNotificationsList,
  // Broadcast
  title = "",
  shortDescription = "",
  broadcastModalRef,
}) => {
  const texts = useText("notificationsList");
  const { notificationTypes, notificationTypesTexts, notificationTime } =
    useTypeNotification();

  // Queries
  const url =
    type === notificationsTypes.BROADCAST
      ? endpoints.notifications.READ_BROADCAST_NOTIFICATION(id)
      : endpoints.notifications.READ_NOTIFICATION(id);

  const deleteNotificationAPI = useQuery({
    url: url,
    method: "POST",
  });

  return (
    <div className={Style.item} id={id}>
      <div className={Style.type}>
        <div className={Style.icon}>
          <img
            as="image"
            rel="preload"
            src={notificationTypes(type)}
            alt=""
            width={35}
          />
        </div>
      </div>
      <div className={Style.text}>
        {type === notificationsTypes.BROADCAST
          ? title
          : notificationTypesTexts(type)}

        {shortDescription || short_description ? (
          <div className={Style.description}>
            {shortDescription || short_description}
          </div>
        ) : null}

        {type === notificationsTypes.BROADCAST ? (
          <div
            className={Style.delete}
            onClick={(e) => {
              if (deleting) {
                broadcastModalRef?.current?.updateBody(
                  <BroadcastDetailsModal
                    broadcastModalRef={broadcastModalRef}
                    id={id}
                    deleting={true}
                  />
                );
                document.getElementById(id).classList.add(Style.deleting);
                setTimeout(() => {
                  document.getElementById(id).classList.remove(Style.deleting);
                  setNotificationsList((old) =>
                    old.filter((el) => id !== el.id)
                  );
                }, 150);
              } else {
                broadcastModalRef?.current?.updateBody(
                  <BroadcastDetailsModal
                    broadcastModalRef={broadcastModalRef}
                    id={id}
                    deleting={true}
                  />
                );
              }
            }}
          >
            {texts?.showDetails}
          </div>
        ) : (
          <div className={Style.time}>
            {notificationTime(createdAt || created_at)}
            {deleting && (
              <div
                className={Style.delete}
                onClick={(e) => {
                  deleteNotificationAPI.executeQuery();
                  document.getElementById(id).classList.add(Style.deleting);
                  setTimeout(() => {
                    document
                      .getElementById(id)
                      .classList.remove(Style.deleting);
                  }, 150);
                  setTimeout(() => {
                    setNotificationsList((old) => {
                      return old.filter((el) => id !== el.id);
                    });
                  }, 150);
                }}
              >
                {texts?.markAsRead}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsList;
