import React, { useRef, useEffect, useContext } from "react";

// Components
import Hero from "../../../components/advanced/hero/Hero";
import ProfileBadge from "../../../components/advanced/profileBadge/ProfileBadge";
import Tooltip from "../../../components/advanced/tooltip/Tooltip";

// Libraries
import { Button, Container, MagicModal } from "@hybris-software/ui-kit";
import { useUser, PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";

// Hooks
import useText from "../../../hooks/useText";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Svgs
import Copy from "../../../assets/svgs/Copy";

// Utils
import dispatcher from "../../../utils/dispatcher";

// Styles
import Style from "./TelegramView.module.css";

const TelegramView = () => {
  // Hooks
  const copy = useText("copyTooltip");
  const texts = useText("telegramView");
  const { userInfo } = useUser();
  const navigate = useNavigate();

  // Refs
  const modalRef = useRef();

  // Contexts
  const { paths } = useContext(RoutesContext);

  // Queries
  const telegramAPI = useQuery({
    url: endpoints.profile.TELEGRAM,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      window.open(response?.data?.url, "_blank", "noopener noreferrer");
    },
  });

  useEffect(() => {
    document.title = `${config.PLATFORM_NAME} | ${texts?.pageTitle}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PermissionRoute
      firstApiLoading={telegramAPI.isLoading}
      minimumLoadingTime={500}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        if (userInfo?.telegramId) navigate(paths.main.profile);
        else dispatcher(response.data, navigate, paths);
        return true;
      }}
    >
      <MagicModal ref={modalRef} />

      <Hero>
        <Container>
          <div className={Style.header}>
            <h3 className={Style.title}>{texts?.title}</h3>
            <div className={Style.referral}>
              <div className={Style.referralText}>
                {texts?.cardReferral}{" "}
                {`${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}` ||
                  "--"}
              </div>

              <Tooltip
                valueToCopy={
                  `${config.BASE_URL}auth/register?refcode=${userInfo?.referralCode}` ||
                  "--"
                }
                copyIcon={<Copy />}
                textCopy={copy?.copied}
              />
            </div>
          </div>

          <ProfileBadge modalRef={modalRef} />
        </Container>
      </Hero>

      <Container>
        <div className={Style.security}>
          <div className={Style.box}>
            <h5>{texts?.title}</h5>
            <p className={Style.subtitle}>{texts?.subtitle}</p>
            <p className={Style.subtitle}>{texts?.didNotOpen}</p>

            <div className={Style.buttons}>
              <Button
                className={Style.save}
                onClick={() => {
                  window.open(
                    telegramAPI?.response?.data?.url,
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
              >
                {texts?.button}{" "}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </PermissionRoute>
  );
};

export default TelegramView;
