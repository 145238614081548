import React, { useState } from "react";

// Components
import ErrorModal from "../../../../../components/advanced/errorModal/ErrorModal";
import ErrorBanner from "../../../../../components/advanced/errorBanner/ErrorBanner";
import Tooltip from "../../../../../components/advanced/tooltip/Tooltip";

// Libraries
import { useUser } from "@hybris-software/use-auth";
import { Button } from "@hybris-software/ui-kit";
import { useOutletContext } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import QRCode from "react-qr-code";
import OtpInput from "react18-input-otp";

// Hooks
import useText from "../../../../../hooks/useText";

// Data
import endpoints from "../../../../../data/endpoints";

// Svgs
import Copy from "../../../../../assets/svgs/Copy";

// Styles
import Style from "./DoubleTwoFactorAuth.module.css";
import Theme from "../../../../../assets/css/theme.module.css";

const DoubleTwoFactorAuth = ({ getOTPCodeAPI }) => {
  // States
  const [otp, setOtp] = useState(0);

  // Hooks
  const texts = useText("securityView")?.doubleTwoFactorAuth;
  const copy = useText("copyTooltip");
  const { refreshUserInfo, userInfo } = useUser();

  // Contexts
  const { unauthorizedModalRef, errorModalRef } = useOutletContext();

  // Queries

  const setOTPMethodAPI = useQuery({
    url: endpoints.doubleTwoFactor.SECONDARY_TOTP,
    method: "POST",
    executeImmediately: false,
    onSuccess: () => {
      refreshUserInfo();
    },
    onError: (error) => {
      if (error.response.status === 422) {
        return true;
      } else if (
        error.response?.status !== 400 &&
        error.response?.status !== 422 &&
        error.response?.status !== 401
      ) {
        errorModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={errorModalRef} />
        );
      }
    },
    onUnauthorized: () => {
      unauthorizedModalRef.current.open();
    },
  });

  // Variables
  const otpErrorMessage = setOTPMethodAPI.isError &&
    setOTPMethodAPI.error.response.status === 422 && {
      googleAuthOtp: (
        <div className={Style.otpErrorMessage}>
          {(setOTPMethodAPI.error.response.data["totpOtp"] && (
            <>Invalid Google Authenticator OTP Code</>
          )) ||
            ""}
        </div>
      ),
    };

  // Functions
  const handleOtpInputChange = (otp) => setOtp(otp);

  return (
    <div className={Style.security}>
      <div className={Style.box}>
        <div className={Style.header}>
          <div>
            <h5>{texts?.title}</h5>
            <p className={Style.subtitle}>{texts?.subheading}</p>
          </div>
        </div>
        <>
          {userInfo.isSecondaryTotpEnabled ? (
            <SuccessMessage text={texts?.successMessage} />
          ) : (
            <div className={Style.body}>
              <div className={Style.section}>
                <ErrorBanner
                  response={setOTPMethodAPI.error?.response}
                  call="changeMethod"
                />
              </div>
              <div className={Style.section}>
                <div>
                  {getOTPCodeAPI?.response?.data?.url && (
                    <QRCode
                      className={Style.qrCode}
                      value={getOTPCodeAPI?.response?.data?.url}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={Style.copySecret}>
                  {texts?.secret}
                  <Tooltip
                    valueToCopy={getOTPCodeAPI?.response?.data?.secret}
                    copyIcon={<Copy />}
                    textCopy={copy?.copied}
                  />
                </div>
                <div className={Style.section}>
                  <div className={Style.otp}>
                    <OtpInput
                      value={otp}
                      onChange={handleOtpInputChange}
                      containerStyle={Theme.otpContainer}
                      inputStyle={Theme.otpInputStyle}
                      focusStyle={Theme.otpFocusStyle}
                      errorStyle={Theme.otpErrorStyle}
                      numInputs={6}
                      isInputNum
                      shouldAutoFocus={true}
                    />
                    <p>{otpErrorMessage.googleAuthOtp}</p>
                  </div>
                </div>
              </div>
              <div className={Style.section}>
                <Button
                  isLoading={setOTPMethodAPI.isLoading}
                  className={Style.button}
                  disabled={!(otp?.length === 6) || setOTPMethodAPI.isLoading}
                  onClick={() => {
                    setOTPMethodAPI.executeQuery({ totpOtp: otp });
                  }}
                >
                  {texts?.action}
                </Button>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

const SuccessMessage = ({ text }) => {
  return <div className={Style.infoApproved}>{text}</div>;
};

export default DoubleTwoFactorAuth;
