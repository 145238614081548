import React, { useRef, useEffect } from "react";

// Libraries
import { useWindowSize } from "@hybris-software/ui-kit";

// Styles
import Style from "./HamburgerMenu.module.css";

const HamburgerMenu = ({ open, setOpen }) => {
  const menuRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (open) document.body.style.overflowY = "hidden";
    else document.body.style.overflowY = "auto";
  }, [open]);

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
      if (window.scrollY === 0) menuRef?.current?.classList.add(Style.open);
    } else menuRef?.current?.classList.remove(Style.open);

    if (width > 820) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, width]);

  return (
    <>
      <div
        ref={menuRef}
        onClick={() => setOpen((old) => !old)}
        className={Style.hamburgerMenu}
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    </>
  );
};

export default HamburgerMenu;
