import { Route } from "react-router-dom";

// Layouts
import AuthLayout from "../layouts/authLayout/AuthLayout";
import MainLayout from "../layouts/mainLayout/MainLayout";
import SsoLayout from "../layouts/ssoLayout/SsoLayout";

// Auth Views
import LoginView from "../view/auth/loginView";
import ResetPasswordView from "../view/auth/resetPasswordView";
import TermsAndConditionsView from "../view/auth/termsAndConditionsView";
import TwoFactorView from "../view/auth/twoFactorView";
import RegisterView from "../view/auth/registerView";
import ReferralCodeView from "../view/auth/referralCodeView";
import ProfileTypeView from "../view/auth/profileTypeView";
import ForgotPasswordView from "../view/auth/forgotPasswordView";
import EnrollView from "../view/auth/enrollView";
import Redirect from "../view/redirect";
import ChangeOTPMethod from "../view/auth/changeOTPMethod";

// Main Views
import DashboardView from "../view/main/dashboardView/";
import ProfileView from "../view/main/profileView/";
import EmailView from "../view/main/emailView/";
import PasswordView from "../view/main/passwordView/";
import SecurityView from "../view/main/securityView/";
import NotificationView from "../view/main/notificationView";
import KycView from "../view/main/kycView/KycView";
import RecentDevicesView from "../view/main/recentDevicesView";
import TelegramView from "../view/main/telegramView";

// SSO
import SsoView from "../view/sso/ssoView/SsoView";

// Generic
import MaintenanceView from "../view/maintenanceView/MaintenanceView";
import SpyLoginView from "../view/auth/spyLoginView/SpyLoginView";

const routes = {
  sso: {
    path: "/",
    element: <SsoLayout />,
    title: "Authentication",
    routes: {
      login: {
        path: "sso",
        element: <SsoView />,
        title: "Login",
        icon: null,
      },
    },
  },
  auth: {
    path: "/auth",
    element: <AuthLayout />,
    title: "Authentication",
    routes: {
      login: {
        path: "/login",
        element: <LoginView />,
        title: "Login",
        icon: null,
      },
      inspect: {
        path: "/inspect",
        element: <SpyLoginView />,
        title: "Inspector",
        icon: null,
      },
      register: {
        path: "/register",
        element: <RegisterView />,
        title: "Register",
        icon: null,
      },
      forgotPassword: {
        path: "/forgot-password",
        element: <ForgotPasswordView />,
        title: "Forgot Password",
        icon: null,
      },
      resetPassword: {
        path: "/reset-password",
        element: <ResetPasswordView />,
        title: "Reset Password",
        icon: null,
      },
      twoFactor: {
        path: "/two-factor",
        element: <TwoFactorView />,
        title: "Two Factor",
        icon: null,
      },
      referralCode: {
        path: "/referral-code",
        element: <ReferralCodeView />,
        title: "Referral Code",
        icon: null,
      },
      profileType: {
        path: "/account-type",
        element: <ProfileTypeView />,
        title: "Profile Type",
        icon: null,
      },
      enroll: {
        path: "/enroll",
        element: <EnrollView />,
        title: "Enroll",
        icon: null,
      },
      termsAndConditions: {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsView />,
        title: "Terms and Conditions",
        icon: null,
      },
      authRedirect: {
        path: "/redirect",
        element: <Redirect />,
        title: "Redirect",
        icon: null,
      },
      changeOTPMethod: {
        path: "/chang-otp",
        element: <ChangeOTPMethod />,
        title: "Change OTP",
        icon: null,
      },
    },
  },
  main: {
    path: "/",
    element: <MainLayout />,
    title: "Main",
    routes: {
      dashboard: {
        path: "",
        element: <DashboardView />,
        title: "Dashboard",
        icon: null,
      },
      profile: {
        path: "profile",
        element: <ProfileView />,
        title: "Profile",
        icon: null,
      },
      email: {
        path: "email",
        element: <EmailView />,
        title: "Email",
        icon: null,
      },
      password: {
        path: "password",
        element: <PasswordView />,
        title: "Password",
        icon: null,
      },
      kyc: {
        path: "kyc",
        element: <KycView />,
        title: "KYC",
        icon: null,
      },
      security: {
        path: "security",
        element: <SecurityView />,
        title: "Security",
        icon: null,
      },
      notification: {
        path: "notification",
        element: <NotificationView />,
        title: "Notification",
        icon: null,
      },
      devices: {
        path: "recent-devices",
        element: <RecentDevicesView />,
        title: "Recent Devices",
        icon: null,
      },
      telegram: {
        path: "telegram-verification",
        element: <TelegramView />,
        title: "Telegram Verification",
        icon: null,
      },
    },
  },
  generic: {
    path: "/generic",
    routes: {
      maintenance: {
        path: "/maintenance",
        element: <MaintenanceView />,
        title: "Login",
        icon: null,
      },
    },
  },
};

function generatePaths(routes, parentPath = "") {
  const paths = {};
  Object.entries(routes).forEach(([routeName, route]) => {
    if (route.routes) {
      paths[routeName] = generatePaths(route.routes, parentPath + route.path);
    } else {
      paths[routeName] = parentPath + route.path;
    }
  });
  return paths;
}

function generateRouteComponents(routes, parentPath = "") {
  if (parentPath === "") {
    return Object.keys(routes).map((routeName, index) => {
      return (
        <Route key={index} element={routes[routeName].element}>
          {generateRouteComponents(
            routes[routeName].routes,
            routes[routeName].path
          )}
        </Route>
      );
    });
  } else {
    const views = [];

    Object.keys(routes).forEach((routeName, index) => {
      if (!routes[routeName].comingSoon) {
        views.push(
          <Route
            key={index}
            path={parentPath + routes[routeName].path}
            element={routes[routeName].element}
          />
        );
      }

      if (routes[routeName].routes) {
        views.push(
          generateRouteComponents(
            routes[routeName].routes,
            parentPath + routes[routeName].path
          )
        );
      }
    });
    return views;
  }
}

const sidebarCategories = [
  {
    id: "blockchainId",
    categoryTitle: "Blockchain ID",
    showInSideBar: false,
    routes: [routes.main.routes.dashboard],
  },
];

function generatePathsWithCategoriesForSidebar(sidebarCategories) {
  const paths = {};
  sidebarCategories.forEach((category) => {
    paths[category.id] = {
      showInSideBar: category.showInSideBar,
      categoryTitle: category.categoryTitle,
      routes: category.routes,
    };
  });
  return paths;
}

const paths = generatePaths(routes);
const routeComponents = generateRouteComponents(routes);
const sidebarRoutes = generatePathsWithCategoriesForSidebar(sidebarCategories);
export { routes, paths, routeComponents, sidebarRoutes };
