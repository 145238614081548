import { createContext, useState, useEffect } from "react";

import useQuery from "@hybris-software/use-query";
import { useUser } from "@hybris-software/use-auth";
import endpoints from "../data/endpoints";

export const LanguageContext = createContext({
    language: "EN",
    setLanguage: () => { },
});

export const LanguageProvider = ({ children }) => {

    // Hooks
    const { userInfo } = useUser();

    const changeLanguageApi = useQuery({
        url: endpoints.profile.PREFERREDLANGUAGE,
        method: "POST",
        onSuccess: (response) => {
            window.location.reload();
        }
    })

    const allLang = ["EN", "ES", "PT", "FR", "DE", "EL", "ZH", "JA", "KO", "RU", "VI", "TH", "CS", "SR", "TR", "HI"];

    const lng = userInfo?.language
        ? userInfo?.language :
        localStorage.getItem("language") === undefined || localStorage.getItem("language") === null || !allLang.includes(localStorage.getItem("language"))
            ? "EN"
            : localStorage.getItem("language");

    const [language, setLanguage] = useState(lng);

    useEffect(() => {
        setLanguage(lng);
    }, [lng]);

    function changeLanguage(lng) {
        changeLanguageApi.executeQuery({
            language: lng,
        });
        localStorage.setItem("language", lng);
    }

    return (
        <LanguageContext.Provider
            value={{
                language: language,
                setLanguage: changeLanguage,
            }}>
            {children}
        </LanguageContext.Provider>
    );
}

