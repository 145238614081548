import config from "./config";

const texts = {
  EN: {
    redirect: {
      pageTitle: "Redirecting...",
    },
    /* Auth */
    authLayout: {
      headerCta: "Already have an account?",
      headerSignin: "Sign in",
      sliderTitle: "Secure Your Digital Life",
      sliderSubtitle: `With ${config.PLATFORM_NAME}, take full control of your personal data in a secure and transparent way.`,
      sliderTitle2: "Seamless Integration",
      sliderSubtitle2:
        "Log in once and access a universe of services without compromising on security.",
      sliderTitle3: "Be the Architect of Your Online Identity",
      sliderSubtitle3:
        "Customize, manage, and authenticate your digital identity like never before.",
      headerCtaSignUp: "Don't have an account?",
      headerSignup: "Sign up",
      logout: "Logout",
    },
    loginView: {
      pageTitle: "Login",
      loginDisabled: "Login not available",
      title: "Sign in to your Account",
      headerCta: "Don't have an account?",
      headerSignup: "Sign up",
      labelEmail: "Username or Email",
      placeholderEmail: "Enter your username or email",
      labelPassword: "Password",
      placeholderPassword: "Enter your password",
      rememberLabel: "Remember me",
      button: "Sign in",
      forgotPassword: "Forgot Password?",
      sessionExpire: "Your session has expired, please login again",
    },
    registerView: {
      pageTitle: "Register an account",
      registrationDisabled: "Registration not available",
      title: "Create an account",
      subtitle: `Enter the details and create your account in ${config.PLATFORM_NAME}`,
      labelUsername: "Username*",
      labelEmail: "Email Address*",
      labelEmailConfirm: "Confirm Email Address*",
      labelCountry: "Country*",
      labelReferral: "Referral Code",
      labelPassword: "Password*",
      labelConfirmPassword: "Confirm Password*",
      placeholderUsername: "Enter your username",
      placeholderEmail: "Enter your email address",
      placeholderEmailConfirm: "Confirm your email address",
      placeholderCountry: "Select your country",
      placeholderReferral: "Enter your referral code",
      placeholderPassword: "8+ characters required",
      rememberLabel: `I accept ${config.PLATFORM_NAME}'s `,
      termsLabel: "Terms of Service and Privacy Policy.",
      button: "Sign Up",
      registrationDone: "Registered",
      registrationDoneSubtitle:
        "Thank you for registering, now you can login with your credentials, click the button below to return to the login page and sign in",
      registrationDoneButton: "Login",
      badRequestMessage: "Registration is disabled",
    },
    forgotPasswordView: {
      pageTitle: "Forgot Password",
      title: "Reset your Password",
      subtitle:
        "Please provide the email address linked to your account for password reset instructions.",
      labelEmail: "Email Address",
      placeholderEmail: "Enter your email address",
      button: "Submit",
      goBack: "Go Back",
      titleSent: "Email Sent",
      subtitleSent:
        "Thank you, check your email for instructions to reset your password ",
      resendTitle: "Don't receive an email?",
      resendCta: "Resend",
      waitPost: "seconds... to request a new email.",
      waitPre: "Please wait",
      notReceived: "Didn't your received the email ?",
    },
    resetPasswordView: {
      pageTitle: "Reset Password",
      title: "Reset your Password",
      subtitle: "Type and confirm a secure new password for the account",
      labelPassword: "New Password",
      placeholderPassword: "8+ characters required",
      labelConfirmPassword: "Confirm Password",
      button: "Submit",
      goBack: "Go Back",
      titleResetted: "Congrats, your password has been reset",
      subtitleResetted:
        "You have successfully reset your password, you can now login with your new password and enjoy the app experience again.",
      logout: "Logout from all devices",
      invalidToken: "Password reset link expired, please request a new one.",
    },
    termsAndConditionsView: {
      pageTitle: "Terms & Conditions",
      title: "Accept Terms & Conditions",
      checkbox: "I agree and accept this Terms & Conditions",
      button: "Submit",
    },
    twoFactorView: {
      pageTitle: "Two-Factor Authentication",
      title: "Verify your identity",
      subtitleEmail: "Type and confirm a secure code for the account",
      subtitleAuthenticator:
        "Type and confirm the secure code from the authenticator app",
      button: "Verify",
      titleExpired: "Session Expired",
      subtitleExpired:
        "Oops, it looks like your session has expired. Please click the button below to return to the login page and sign in again.",
      expiredButton: "Sign in again",
    },
    referralCodeView: {
      pageTitle: "Referral Code",
      title: "Referral Code",
      subtitle: "Enter the referral code to get bonus",
      labelReferral: "Referral Code",
      doNotHaveReferral:
        "If you do not have a referral code please fill the following form",
      placeholderReferral: "Enter your referral code",
      button: "Submit",
      buttonFillForm: "Fill form",
      skip: "Skip",
    },
    profileTypeView: {
      pageTitle: "Account Type",
      title: "Account Type",
      subtitle: "Select which type of account you want to have",
      personalTitle: "Personal Account",
      personalSubtitle:
        "Choose this account type related to natural persons, if you are registering as such",
      companyTitle: "Company Account",
      companySubtitle:
        "Choose this type of company-related account, if you are registering as such",
      button: "Submit",
    },
    enrollView: {
      pageTitle: "Enroll Now",
      title: "Enroll Now",
      subtitle: "Register to our super platform",
      button: "Continue",
      skip: "Skip and Continue",
    },
    changeOTPMethod: {
      action: "Change your method",
      desc: "Please follow these steps to update your authentication method to Google OTP and continue:",
      stepOne: `Download and install "Google Authenticator" app from your mobile device's app store.`,
      stepTwo:
        "Set up a new account using the QR Code or  by copying the auth key below.",
      stepThree: `Verify the configuration by entering the code that "Google Authenticator" provides.`,
      pageTitle: "Change OTP method",
      title: "Change OTP method to Google",
      secret: "Copy Google Auth Key",
    },
    /* Main */
    dashboardView: {
      pageTitle: "Dashboard",
      title: "Dashboard",
      favoritesLabel: "Favorites",
      platformLabel: "Platforms",
      comingSoonLabel: "Coming Soon",
      migrationsLabel: "Migrations",
      cardReferral: "Referral:",
      overview: {
        id: "ID: ",
        title: "Overview",
        profile: "Profile Completion",
        profileProgerss: "completed",
        device: "Logged in Devices",
        referral: "Total Referral",
        password: "Last Password Changed",
        minute: "Minute",
        hour: "Hour",
        day: "Day",
        profileStep:
          "Your Profile is not completed yet. Click the button below to complete the next step.",
        stepsListTitle: "Steps to complete your profile:",
        securityCode: "- Set Security Code",
        profilePicture: "- Change Profile Picture",
        kyc: "- Submit KYC",
        kyb: "- Submit KYB",
        profileInfo: "- Update Profile Information",
        changeOtp: "- Enable 2FA with Authenticator",
        congrats: "Congrats! All steps are completed.",
        timePassword: "Days ago",
      },
      activities: {
        title: "Activities",
        empty: "No activities",
      },
    },
    profileView: {
      pageTitle: "My Profile",
      title: "My Profile",
      cardReferral: "Referral:",
      personalInformation: {
        title: "Personal Information",
        labelName: "First Name",
        labelLastName: "Last Name",
        labelCountry: "Country",
        labelAccount: "Account Type",
      },
      residentialAddress: {
        title: "Residential Address",
        labelCountry: "Country",
        labelCity: "City",
        labelAddress: "Address",
        labelZipCode: "Zip Code",
        labelPhone: "Phone Number",
        labelState: "State",
        editButton: "Edit",
        saveButton: "Save",
        placeholderCountry: "Select your country",
        placeholderCity: "Enter your city",
        placeholderAddress: "Enter your address",
        placeholderZipCode: "Enter your zip code",
        placeholderState: "Enter your state",
      },
      telegramInfo: {
        title: "Telegram Account",
        labelId: "Telegram ID",
        labelUsername: "Telegram Username",
      },
    },
    emailView: {
      pageTitle: "Email",
      title: "Email",
      cardReferral: "Referral:",
      changeEmail: {
        title: "Email",
        subtitle: `Your email address serves as a crucial identifier and means of communication within the ${config.PLATFORM_NAME} platform. Changing your email address is a simple but important action that requires verification for your security. Remember, updating your email will also change the login credentials for your ${config.PLATFORM_NAME} account.`,
        labelEmail: "New Email Id",
        placeholderEmail: "Enter your email address",
        editButton: "Edit",
        saveButton: "Save",
      },
    },
    passwordView: {
      pageTitle: "Password",
      title: "Password",
      cardReferral: "Referral:",
      changePassword: {
        title: "Change Password",
        subtitle: `Your password is a critical component of your account security. To help keep your ${config.PLATFORM_NAME} account safe, it's good practice to update your password regularly. When you change your password, make sure to choose a strong, unique passphrase that you haven't used elsewhere. After you've initiated the change, you'll need to verify it for added security. Follow the on-screen prompts to successfully update your password.`,
        labelPassword: "Enter your current password",
        labelNewPassword: "New Password",
        labelConfirmPassword: "Confirm new Password",
        placeholderPassword: "Enter your current password",
        placeholderNewPassword: "Enter your new password",
        placeholderConfirmPassword: "Confirm your new password",
        saveButton: "Save",
      },
    },
    kycView: {
      pageTitle: "KYC",
      pageTitleCompany: "KYB",
      title: "Verify your Identity",
      cardReferral: "Referral:",
      startKyc: {
        title: "KYC",
        subtitle: "Verify your identity to increase your account limits",
        buttonStart: "Start KYC",
        kycApproved:
          "Your KYC has been approved, you can now enjoy the full experience of the platforms. It was approved on",
        kycDisabled:
          "The KYC feature is disabled for now, please try again later",
        purchaseNotCompleted: `You have to complete a membership purchase in ${config.LFI_PLATFORM_NAME} platform`,
        badRequestMessage: "Invalid account type",
      },
      startKyb: {
        title: "KYB",
        subtitle: "Verify your identity to increase your account limits",
        buttonStart: "Start KYB",
        kycApproved:
          "Your KYB has been approved, you can now enjoy the full experience of the platforms. It was approved on",
        kycDisabled:
          "The KYB feature is disabled for now, please try again later",
        purchaseNotCompleted: `You have to complete a membership purchase in ${config.LFI_PLATFORM_NAME} platform`,
        badRequestMessage: "Invalid account type",
      },
    },
    securityView: {
      pageTitle: "Security",
      title: "Security",
      cardReferral: "Referral:",
      twoFactor: {
        title: "Two-Factor Authentication",
        subtitle: "Google Authenticator:",
        subtitleEmail: "Code sent via Email",
        istructionEmail: "Check your email for the code and enter it below",
        emailTitle: "Enter the authentication code sent to your email:",
        emailSubtitle:
          "We have sent you an e-mail with a 6-digit code. Enter the code below to activate two-factor authentication.",
        instructionAuth:
          "Check your authenticator app for the code and enter it below",
        instructionTitle:
          "Enter the authentication code from authenticator app",
        instructionSubtitle: "Find the code and enter the 6-digit code below.",
        istruction:
          "Scan the image below with the two factor authentication app",
        qrTitle: "Enter the authentication code generated by the app:",
        qrSubtitle:
          "After scanning the QR code, the app wil display a digit code that you can enter below.",
        buttonVerify: "Verify",
      },
      securityCode: {
        title: "Set Security Code",
        subtitle: "For more security, set a security code:",
        subheading: `Enhance your account security with ${config.PLATFORM_NAME}'s 'Set Security Code' feature. This function provides an additional layer of protection, beyond Google Authenticator, to ensure the utmost security of your account.`,
        resetButton: "Reset Code",
        labelCode: "Set an 8-digit code for enhanced security",
        placeholderCode: "Enter your code",
        saveButton: "Save",
      },
      keyGenerator: {
        title: "Encrypted Key",
        subheading: "",
      },
      doubleTwoFactorAuth: {
        title: " Second Factor Authentication (2FA) Integration",
        subheading: `Introducing an additional layer of access control for your account with our platform's Second Factor Authentication (2FA) integration. Enable a second verification step now and verify the configuration by entering the code that "Google Authenticator" provides.`,
        secret: "Copy Google Auth Key",
        action: "Verify your second 2FA",
        titleExpired: "Session Expired",
        subtitleExpired:
          "Oops, it looks like your session has expired. Please click the button below to return to the login page and sign in again.",
        expiredButton: "Sign in again",
        successMessage: `Your Second Factor Authentication (2FA) has been successfully verified. Your  ${config.PLATFORM_NAME} account is now secured with two Google Authenticator accounts.`,
      },
    },
    notificationView: {
      pageTitle: "Notification",
      title: "Notification",
      cardReferral: "Referral:",
      notify: {
        title: "Notification",
        subtitle: `Stay informed and secure with ${config.PLATFORM_NAME}'s personalized notification system. Tailor your experience by choosing to receive notifications via email or browser alerts. You can manage the types of notifications you receive to focus on what matters most to you:`,

        typeNews: "News For You",
        typeActiviry: "Account Activity",
        typeDevices: "Login Activities",
        labelBrowser: "Browser",
        labelEmail: "Email",
        newsForYouSub: `Be the first to know about updates, new features, and opportunities that are relevant to your ${config.PLATFORM_NAME} experience`,
        accountActivity:
          "Get alerted for account-related activities such as updates, changes, or transactions to keep you in the loop about your account status.",
        LoginActivities: `Receive immediate alerts for login attempts, helping you monitor unauthorized access and maintain optimal security for your ${config.PLATFORM_NAME} account.`,
      },
    },
    devicesView: {
      pageTitle: "Recent Devices",
      title: "Recent Devices",
      cardReferral: "Referral:",
      devices: {
        title: "Recent Devices",
        tableColumnOne: "Device Type",
        tableColumnTwo: "Browser",
        tableColumnThree: "Country",
        tableColumnFour: "City",
        tableColumnFive: "Last Interaction",
      },
      table: {
        page: "Page",
        of: "of",
        total: "records",
        buttonPrev: "Previous",
        buttonNext: "Next",
        sortByAsc: "Sort by ASC",
        sortByDesc: "Sort by DESC",
        hideColumn: "Hide this column",
        showColumns: "Show all columns",
      },
    },

    telegramView: {
      pageTitle: "Link Telegram",
      title: "Link Your Telegram",
      subtitle: "Click Send message to verify your Telegram ID.",
      didNotOpen:
        "If the link  did not open automatically, click on the button below.",
      button: "Verify Telegram",
    },
    ssoView: {
      pageTitle: "Single Sign-On",
      cta: "Are you sure to want",
      title: "Login to",
      buttonExit: "Exit",
      buttonProceed: "Proceed",
      badRequestMessage:
        "There was an error in the parameters, please try again later",
      moreStepsRequire: "Forbidden",
      moreStepsRequireDesc: "You need to complete some steps before login",
      countryNotAccepted:
        "Your country is currently not accepted for the platform.",
      proceed: "Complete account",
    },
    /* Utils */
    platformCard: {
      loginButton: "Login",
      enrollButton: "Enroll",
      comingButton: "Coming Soon",
      users: "Users",
      loginTitlePopup: "Did not open automatically?",
      loginSubtitlePopup: "Click the button below to open the platform.",
      loginButtonPopup: "Go to the Platform",
    },
    validatorPassoword: {
      infomation:
        "Required at least 8 characters, 1 lower and upper case letter, 1 number and one special character.",
    },
    copyTooltip: {
      copied: "Copied!",
    },
    profileBadge: {
      date: "Joined on:",
      id: "ID: ",
      businessCard: "Business Card",
    },
    generalError: {
      title: "Oops, something went wrong",
      subtitle:
        "We are sorry, but something went wrong. Please try again later.",
      button: "Close",
    },
    changeAvatar: {
      title: "Change your avatar",
      maximum: "Maximum",
      size: "Size",
      uploadedSize: "Uploaded size",
      onSize: "on",
      allowedFile:
        "Allowed extensions [ jpg - jpeg - gif - png - webp - heic ]",
      buttonSave: "Save",
      uploading: "Uploading...",
    },
    twoFactor: {
      verifyIdentity: "Verify your identity",
      checkEmail: "Please check your inbox for verification code sent to",
      verifyAuth: "Please check your",
      nameAuth: "authenticator app",
      verifyAuthCode: "for the code and enter it below",
      button: "Verify",
    },
    doubleTwoFactor: {
      verifyIdentity: "Verify your identity",
      checkEmail: "Please check your inbox for verification code sent to",
      checkNewEmail:
        "Please check your inbox for verification code sent to your new email",
      verifyAuth: "Please check your",
      nameAuth: "authenticator app",
      verifyAuthCode: "for the code and enter it below",
      button: "Verify",
    },
    languages: {
      title: "Change Language",
    },
    logout: {
      title: "Are you sure you want to logout?",
      subtitle:
        "You are about to log out of the platform, you can disconnect all devices if necessary.",
      label: "Logout from all your devices",
      button: "Logout",
    },
    menu: {
      dashboard: "Dashboard",
      profile: "Profile",
      email: "Email",
      password: "Password",
      kyc: "KYC",
      kyb: "KYB",
      security: "Security",
      notifications: "Notifications",
      devices: "Recent Devices",
      support: "Support",
      telegram: "Link Telegram",
    },
    sendCodeAgain: {
      notReceived: "Didn't your received the 2FA?",
      resend: "Send Again",
      waitPre: "Please wait",
      waitPost: "seconds... to request a new code.",
    },
    success: {
      buttonClose: "Close",
      avatarTitle: "Congrats, your avatar has been updated",
      avatarSubtitle:
        "You have successfully updated your avatar, you can now see it in your profile.",
      passwordTitle: "Congrats, your password has been updated",
      passwordSubtitle: "You have successfully updated your password.",
      keyGeneratorTitle: "Lorem ipsum dolor sit amet, consectetur.",
      keyGeneratorSubtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit  wifueg wifu pwfqug wpfog wf p9uwg f.",
      emailTitle: "Congrats, your email has been updated",
      emailSubtitle: "You have successfully updated your email address.",
      informationTitle: "Congrats, your information has been updated",
      informationSubtitle: "You have successfully updated your information.",
      twoFactorTitle: "Congrats, your 2FA has been updated",
      twoFactorSubtitle: "You have successfully updated your 2FA.",
      securityCodeTitle: "Congrats, your security code has been updated",
      securityCodeSubtitle: "You have successfully updated your security code.",
      enrollPlatformTitle: "Congrats, the platform has been enrolled",
      enrollPlatformSubtitle: "You have successfully enrolled a platform.",
    },
    errorCodes: {
      login: {
        FEATURE_DISABLED: "This feature is disabled",
        INVALID_CREDENTIALS: "Username or password aren't correct",
        USER_BLOCKED: "User deactivated",
      },
      loginConfirm: {
        FEATURE_DISABLED: "This feature is disabled",
        USER_BLOCKED: "User deactivated",
      },
      register: {
        FEATURE_DISABLED: "This feature is disabled",
        REFERRAL_CODE_ALREADY_SUBMITTED: "Referral code already submitted",
        INVALID_REFERRAL: "Invalid referral code",
      },
      submitReferral: {
        REFERRAL_CODE_ALREADY_SUBMITTED: "Referral code already submitted",
        INVALID_REFERRAL: "Invalid referral code",
      },
      accountType: {
        ACCOUNT_TYPE_ALREADY_SET: "Account type already set",
      },
      changePassword: {
        PASSWORD_CHANGE_REQUEST_NOT_FOUND: "Password change request not found",
      },
      changeMethod: {
        OTP_ALREADY_ENABLED: "OTP method already set",
      },
      enroll: {
        ENROLL_ERROR:
          "Your selected country is currently not accepted for the platform.",
        ENROLL_ERROR_TITLE: "Not Allowed.",
      },
    },
    notificationsList: {
      title: "Recent Activities",
      login: "You have successfully logged into your account. Happy browsing!",
      platformLogin: "You have logged in to one of our associated platforms",
      referral:
        "Great news! Your referral code has been used by a new user to sign up. Excellent work in growing our community!",
      device:
        "Attention! An unknown device has just connected to your account. If you haven't done this, please verify the security of your account.",
      password:
        "Important: Your account password has just been changed. If you haven't done this, please verify the security of your account.",
      securityCode:
        "Attention! The security code for your account has just been changed. If you haven't done this, please verify the security of your account",
      email:
        "Important: Your contact email has been changed. If you haven't done this, please verify the security of your account.",
      enroll:
        "Congratulations! You have successfully enrolled in a new platform. Now you can enjoy the services and opportunities offered by this platform.",
      kycApporved:
        "Fantastic! The verification process of your data has been successfully completed. Your account is now fully active and ready to be used to the fullest.",
      kycRejected:
        "We are sorry, but the verification process of your data has not been approved. Please review the provided information and try again.",
      tfa: "Congratulations! You have successfully set up two-factor authentication to enhance the security of your account.",
      residentialInfo: "Your residential information has been changed.",
      dayAgo: "days ago",
      hourAgo: "hours ago",
      minuteAgo: "minutes ago",
      secondAgo: "seconds ago",
      now: "now",
      newNotifications: "New",
      allNotifications: "All",
      activities: "Activities",
      loadMore: "Load More",
      reload: "Reload",
      markAsRead: "Mark as Read",
      empty: "No notifications",
      showDetails: "Show Details",
    },
    inputs: {
      mandatory: "This field is required",
      invalidEmail: "Invalid email",
      notMatch: "Passwords do not match",
      securityCode: "Security code must be 8 digits",
    },

    businessCard: {
      title: "Share referral & get benefit!",
      invite: "Special invite from",
      invitation: ` Hey friends! I'm excited to share this QR code with you to join
      2access.io. Just a quick scan and you're in for an amazing
      experience!`,
      social: "Share with a friend",
      referral: "Referral Link",
    },
  },
  ES: {},
};

export default texts;
