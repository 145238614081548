import React, { useContext, useEffect, useState } from "react";

// Components
import Notification from "../../advanced/notification/Notification";
import Logout from "../../advanced/logout/Logout";
import HamburgerMenu from "../../advanced/hamburgerMenu/HamburgerMenu";

// Libraries
import { useUser } from "@hybris-software/use-auth";
import { Container, useWindowSize } from "@hybris-software/ui-kit";
import { useLocation, useNavigate } from "react-router-dom";

// Data
import config from "../../../data/config";
import { accountType } from "../../../data/constants";

// Icons
import { FaChevronDown } from "react-icons/fa";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Hooks
import useText from "../../../hooks/useText";

// Styles
import Style from "./Navbar.module.css";

const Navbar = ({
  openMobile,
  setOpenMobile,
  openNotify,
  setOpenNotify,
  logoutRef,
  telegramAPI,
}) => {
  // Hooks
  const texts = useText("menu");
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { userInfo } = useUser();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const [open, setOpen] = useState(false);

  const menuRoutes = [
    {
      name: texts?.dashboard,
      path: paths?.main?.dashboard,
      showInNavbar: true,
    },
    {
      name: texts?.profile,
      path: paths?.main?.profile,
      showInNavbar: true,
    },
    {
      name: texts?.email,
      path: paths?.main?.email,
      showInNavbar: true,
    },
    {
      name: texts?.password,
      path: paths?.main?.password,
      showInNavbar: true,
    },
    {
      name:
        userInfo?.accountType === accountType.PERSONAL
          ? texts?.kyc
          : texts?.kyb,
      path: paths?.main?.kyc,
      showInNavbar: true,
    },
    {
      name: texts?.security,
      path: paths?.main?.security,
      showInNavbar: true,
    },
    {
      name: texts?.notifications,
      path: paths?.main?.notification,
      showInNavbar: true,
    },
    {
      name: texts?.devices,
      path: paths?.main?.devices,
      showInNavbar: true,
    },
    {
      name: texts?.support,
      showInNavbar: true,
    },
    {
      name: texts?.telegram,
      path: paths?.main?.telegram,
      showInNavbar: userInfo?.telegramId === null,
    },
  ];

  useEffect(() => {
    if (width > 1495) setOpen(false);
  }, [width]);

  return (
    <header
      className={Style.header}
      style={
        sessionStorage.getItem("inspectorTool") === "true"
          ? { marginTop: 30 }
          : {}
      }
    >
      <Container>
        <div className={Style.navbar}>
          <div className={Style.menu}>
            <div className={Style.logo}>
              <img
                as="image"
                rel="preload"
                src={config.LOGO_WHITE}
                alt=""
                draggable={false}
                onClick={() => navigate(paths?.main?.dashboard)}
              />
              <div className={Style.pipe} />
            </div>

            <div className={Style.responsive}>
              <div className={Style.nav}>
                <ul>
                  {menuRoutes.map((route, index) => {
                    if (!route.showInNavbar) return null;
                    else
                      return (
                        <li
                          key={index}
                          className={
                            route.path === location.pathname
                              ? Style.active
                              : undefined
                          }
                          onClick={() => {
                            if (route.path) {
                              navigate(route.path);
                            } else {
                              window.open(config.SUPPORT_LINK, "_blank");
                            }
                          }}
                        >
                          {route.name}
                        </li>
                      );
                  })}
                </ul>
              </div>

              <div className={Style.queryNav}>
                <div
                  onClick={() => setOpen((old) => !old)}
                  className={open ? Style.opened : Style.toOpen}
                >
                  <FaChevronDown />
                </div>

                <div
                  className={open ? Style.dropdown : Style.notDropdown}
                  onMouseLeave={() => setOpen(false)}
                >
                  <ul className={Style.responsiveList}>
                    {menuRoutes.map((route, index) => {
                      if (!route.showInNavbar) return null;
                      else
                        return (
                          <li
                            key={index}
                            className={
                              route.path === location.pathname
                                ? Style.active
                                : undefined
                            }
                            onClick={() => {
                              if (route.path) {
                                navigate(route.path);
                              } else {
                                if (route.callAPI) {
                                  telegramAPI.executeQuery();
                                } else {
                                  window.open(config.SUPPORT_LINK, "_blank");
                                }
                              }
                            }}
                          >
                            {route.name}
                          </li>
                        );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className={Style.actions}>
            {/* <Languages type="dark" setOpenMobile={setOpenMobile} /> */}
            <Notification open={openNotify} setOpen={setOpenNotify} />
            <Logout logoutRef={logoutRef} />
            <HamburgerMenu open={openMobile} setOpen={setOpenMobile} />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Navbar;
