import React from "react";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useWindowSize } from "@hybris-software/ui-kit";

// Loader
import { TailSpin } from "react-loading-icons";

// Data
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./BroadcastDetailsModal.module.css";

const BroadcastDetailsModal = ({ id }) => {
  // Hooks
  const { width } = useWindowSize();
  // Queries
  const broadcastDetailsAPI = useQuery({
    url: endpoints.notifications.READ_BROADCAST_NOTIFICATION(id),
    method: "POST",
    executeImmediately: true,
  });

  return (
    <div className={Style.logoutModal}>
      {broadcastDetailsAPI?.isLoading ? (
        <div className={Style.loaderContainer}>
          <TailSpin stroke="black" />
        </div>
      ) : (
        broadcastDetailsAPI?.isSuccess && (
          <>
            <h5 className={Style.title}>
              {broadcastDetailsAPI?.response?.data?.title}
            </h5>

            {width > 576 ? (
              <img
                src={broadcastDetailsAPI?.response?.data?.image}
                alt="broadcast"
                className={Style.image}
              />
            ) : (
              <img
                src={broadcastDetailsAPI?.response?.data?.mobileImage}
                alt="broadcast"
                className={Style.image}
              />
            )}

            <p className={Style.info}>
              <div
                dangerouslySetInnerHTML={{
                  __html: broadcastDetailsAPI?.response?.data?.description,
                }}
              />
            </p>
          </>
        )
      )}
    </div>
  );
};

export default BroadcastDetailsModal;
