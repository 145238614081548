// Images
import NotKyc from "../assets/images/not-kyc.svg";
import NotUpdate from "../assets/images/not-update.svg";
import NotSettings from "../assets/images/not-settings.svg";
import NotReject from "../assets/images/not-reject.svg";
import NotEnroll from "../assets/images/not-enroll.svg";
import NotEmail from "../assets/images/not-email.svg";
import NotPassword from "../assets/images/not-password.svg";
import NotReferral from "../assets/images/not-referral.svg";
import NotLogin from "../assets/images/not-login.svg";
import NotDevice from "../assets/images/not-device.svg";
import NotBroadcast from "../assets/images/not-broadcast.svg";

// Hooks
import useText from "./useText";

// Data
import { notificationsTypes } from "../data/constants";

const useTypeNotification = () => {
  // Hooks
  const texts = useText("notificationsList");

  const typeIcon = {
    [notificationsTypes.LOGIN]: NotLogin,
    [notificationsTypes.REFERRAL]: NotReferral,
    [notificationsTypes.NEW_DEVICE]: NotDevice,
    [notificationsTypes.SECURITY_CODE]: NotPassword,
    [notificationsTypes.PASSWORD_CHANGE]: NotPassword,
    [notificationsTypes.EMAIL_CHANGE]: NotEmail,
    [notificationsTypes.PLATFORM_LOGIN]: NotLogin,
    [notificationsTypes.PLATFORM_ENROLLMENT]: NotEnroll,
    [notificationsTypes.KYC_APPROVED]: NotKyc,
    [notificationsTypes.KYC_REJECTED]: NotReject,
    [notificationsTypes.TFA_SETTINGS]: NotSettings,
    [notificationsTypes.RESIDENTIAL_INFO_CHANGE]: NotUpdate,
    [notificationsTypes.BROADCAST]: NotBroadcast,
  };

  const typeTexts = {
    [notificationsTypes.LOGIN]: texts?.login,
    [notificationsTypes.REFERRAL]: texts?.referral,
    [notificationsTypes.NEW_DEVICE]: texts?.device,
    [notificationsTypes.SECURITY_CODE]: texts?.securityCode,
    [notificationsTypes.PASSWORD_CHANGE]: texts?.password,
    [notificationsTypes.EMAIL_CHANGE]: texts?.email,
    [notificationsTypes.PLATFORM_LOGIN]: texts?.platformLogin,
    [notificationsTypes.PLATFORM_ENROLLMENT]: texts?.enroll,
    [notificationsTypes.KYC_APPROVED]: texts?.kycApporved,
    [notificationsTypes.KYC_REJECTED]: texts?.kycRejected,
    [notificationsTypes.TFA_SETTINGS]: texts?.tfa,
    [notificationsTypes.RESIDENTIAL_INFO_CHANGE]: texts?.residentialInfo,
  };

  const notificationTypes = (type) => {
    const theType = typeIcon[type];
    if (theType) return theType;
    else return type;
  };

  const notificationTypesTexts = (type) => {
    const theType = typeTexts[type];
    if (theType) return theType;
    else return type;
  };

  const notificationTime = (time) => {
    const date = new Date(time);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} ${texts?.dayAgo}`;
    else if (hours > 0) return `${hours} ${texts?.hourAgo}`;
    else if (minutes > 0) return `${minutes} ${texts?.minuteAgo}`;
    else if (seconds > 0) return `${seconds} ${texts?.secondAgo}`;
    else return texts?.now;
  };

  return { notificationTypes, notificationTypesTexts, notificationTime };
};

export default useTypeNotification;
